import React from "react";
import { ElementsIds } from "../../constants";
import "./index.scss";

export default function Links() {
  return (
    <div className="links-wrapper">
      <a href={`#${ElementsIds.TheSaga}`}>THE SAGA</a>
      <a href={`#${ElementsIds.TheBook}`}>THE BOOK</a>
      <a href={`#${ElementsIds.TheAuthor}`}>THE AUTHOR</a>
      <a href={`#${ElementsIds.Collaborations}`}>COLLABORATIONS</a>
      <a href={`#${ElementsIds.Contact}`}>CONTACT</a>
    </div>
  )
}

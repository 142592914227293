import React, { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { SampleImage } from "../../types";
import "./index.scss";

interface Props {
  imageData: SampleImage
}

export default function Image({ imageData }: Props) {
  const [fullscreen, setFullscreen] = useState(false)
  const { name, title, location, text } = imageData;

  const toggle = () => setFullscreen(fullscreen => !fullscreen)

  const imageElement = useMemo(() => (
    <figure>
      <img className={fullscreen ? "fullscreen-image" : "preview-image"} src={require(`../../assets/images/samples/${name}.jpg`)} loading="lazy" alt="img" />
      {fullscreen && (
        <figcaption className="image-caption">
          <span className="image-title">{title}.&nbsp;</span>
          <span className="image-location">{location}</span>
        </figcaption>
      )}
    </figure>
  ), [fullscreen, name, location, title])

  useEffect(() => {
    if (fullscreen) document.body.style.overflow = "hidden"
    else document.body.style.overflow = "scroll"
  }, [fullscreen])

  if (fullscreen) {
    return createPortal(
      <div className="image-fullscreen-wrapper">
        <div className="close-btn" onClick={toggle}>&times;</div>
        <div className="image-and-text-wrapper">
          {imageElement}
          <div className="image-text">{text}</div>
        </div>
      </div>,
      document.body
    )
  }

  return (
    <div className="image-preview-wrapper" onClick={toggle}>
      {imageElement}
    </div>
  )
}

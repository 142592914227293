import React from "react";
import { ElementsIds } from "../../constants";
import SocialLinks from "../SocialLinks/SocialLinks";
import "./index.scss";

export default function Contact() {
  return (
    <div id={ElementsIds.Contact} className="contact-wrapper">
      <div className="text">
        <SocialLinks />
      </div>
    </div>
  )
}

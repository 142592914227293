import React from "react";
import { Links, PRIVATE_EMAIL } from "../../constants";
import InstegramIcon from "../../assets/icons/Instegram.icon";
import FacebookIcon from "../../assets/icons/Facebook.icon";
import "./index.scss";

export default function SocialLinks() {
  return (
    <div>
      <a href={Links.MOSAIC_OF_EARTH} target="_blank" rel="noreferrer">
        <InstegramIcon />
      </a>
      <a href={Links.FACEBOOK_PROFILE} target="_blank" rel="noreferrer">
        <FacebookIcon />
      </a>
      <a href={`mailto:${PRIVATE_EMAIL}`} className="email">
        Email at {PRIVATE_EMAIL}
      </a>
    </div>
  )
}

import React from "react";
import "./index.scss";

export default function Home() {

  return (
    <div className="home-wrapper">
      <div className="cover-image">
        <h1 className="title">
          <span>Mosaic </span>
          <span>of </span>
          <span>Earth</span>
        </h1>
      </div>
    </div>
  )
}


import React, { useEffect, useState } from "react";
import Links from "../Links/Links";
import "./index.scss";

const HEADER_SCROLL_THRESHOLD = 150;

export default function Header() {
  const [show, setShow] = useState(true);

  const handleScroll = () => {
    if (window.pageYOffset > HEADER_SCROLL_THRESHOLD) {
      setShow(false);
    } else {
      setShow(true);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  }, [])

  return (
    <header className={`header ${show ? "show" : "hide"}`}>
      <Links />
    </header>
  )
}

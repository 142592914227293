import React from "react";
import "./index.scss";

export default function Footer() {
  return (
    <footer className="footer-wrapper">
      <h3>MOSAIC OF EARTH</h3>
      <h6>A project by Roie Natan © 2025</h6>
    </footer>
  )
}

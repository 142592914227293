import { SampleImage } from "./types";

export const Links = {
  PEOPLE_OF_THE_PLANET: "https://www.instagram.com/people.of.the.planet",
  FACEBOOK_PROFILE: "https://www.facebook.com/roie1993",
  MOSAIC_OF_EARTH: "https://www.instagram.com/mosaic.of.earth",
}

export enum ElementsIds {
  TheSaga = "saga",
  TheAuthor = "author",
  TheBook = "book",
  Collaborations = "collaborations",
  Contact = "contact",
}

export const PRIVATE_EMAIL = "roie.natan@gmail.com";

export const COUNTRIES_TRAVELLED = "70";

export const SAMPLE_IMAGES: SampleImage[] = [
  {
    name: "img_2",
    title: "The Apatani Tribe",
    location: "Ziro Valley, Arunachal Pradesh, India",
    text: "In the secluded region of Ziro Valley, one can find the exclusive presence of the Apatani tribe.According to the most renowned legend, the enchanting beauty of Apatani maidens made them targets for abductions by neighboring tribesmen. As a protective measure, and to mark their identity, the tribe resolved to embellish their faces with intricate tattoos and to adorn them with distinctive nose plugs — a process ostensibly designed for 'uglification'. Ironically, my visit to this unique region coincided with International Women's Day. In contemporary times, the burgeoning generation of the Apatani tribe has chosen to abandon this ancestral rite. The women of this tribe are the very last on earth carrying this ancient tradition.",
  },
  {
    name: "img_3",
    text: "In Uzbekistan, myriad breads grace our tables — from the delicate loaves of Tashkent to the flavorsome rounds from Bukhara and Khiva. Yet, standing unparalleled in its reputation and grandeur is the Obi Non of Samarkand, hailed as the crowning jewel of Uzbekistani Non. Ancient tales whisper of a custom where, before embarking on a voyage, a man would take a tender bite from his cherished Uzbek Non. The remaining loaf, then, would find its place adorning the wall, patiently awaiting the traveler's homecoming. In the annals of the Özbeg Khan dynasty, there unfolds another tale: the Khan, captivated by the unique essence of Samarkand bread, commissioned his courtiers to summon the finest bakers and their tools from the city of Samarkand to recreate this marvel in Bukhara. Yet, the replicated bread lacked the ethereal touch of the original. Perplexed, when the Khan sought an answer from the artisans, they responded with poetic candor, ”No matter what we carry with us, the ethereal breath of Samarkand's air eludes our grasp”.",
  },
  {
    name: "img_1",
    text: "Approximately 10,000 years ago the emergence of agricultural societies marked a pivotal epoch when humanity commenced the domestication of flora and fauna. The equines we behold today epitomize a testament to this profound domestication, serving humanity's manifold needs. Yet, in a secluded enclave, distanced from the throbbing pulse of modernization and industry, there exists a singular breed of horse that retains its untamed spirit even now. Though on the brink of extinction, this majestic creature graces the vast expanses of the Mongolian steppes — the Przewalski's horse.",
  },
  {
    name: "img_4",
    text: "[In Progress]",
  },
  {
    name: "img_5",
    text: "[In Progress]",
  },
  {
    name: "img_6",
    text: "[In Progress]",
  },
  {
    name: "img_7",
    text: "[In Progress]",
  },
  {
    name: "img_8",
    text: "[In Progress]",
  },
  {
    name: "img_9",
    text: "[In Progress]",
  },
  {
    name: "img_10",
    text: "[In Progress]",
  },
  {
    name: "img_11",
    text: "[In Progress]",
  },
]
